<template>
  <div>
    <div class="nav-zone">
      <div class="left-zone">
        <el-image class="logo" :src="require('@/assets/favicon.jpg')"></el-image>
        <h3 class="title">{{ title }}</h3>
      </div>
      <div class="right-zone">
        <el-button class="btn-help" icon="el-icon-question" size="mini" type="primary"
          @click.stop="startGuide">帮助</el-button>
        <el-button type="primary" icon="el-icon-setting" circle @click="openDrawer" class="btn-setting" size="mini"
          :autofocus="false">
        </el-button>
        <el-link class="btn-about-me" href="http://www.wangpeng.ukelele.com.cn" target="_blank">关于我</el-link>
        <el-popover placement="top-start" width="200" trigger="hover" class="btn-contact">
          <template>
            <div>
              <el-image slot="reference" :src="require('@/assets/qq_erwei_code.jpg')"></el-image>
            </div>
          </template>
          <el-button slot="reference" size="mini" type="text" icon="el-icon-message">联系我</el-button>
        </el-popover>
      </div>
    </div>
    <div>
      <el-drawer title="设置" :visible.sync="drawer" :direction="direction" :before-close="handleClose" size="40%">
        <el-form ref="form" label-width="120px">
          <el-form-item label="频率">
            <el-select v-model="settingForm.interval" placeholder="选择频率">
              <el-option :label="item.label" v-bind:value="item.value" v-bind:key="item.label"
                v-for="item in intervalItems"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模式">
            <el-select v-model="mode.selectMode">
              <el-option v-for="item in mode.options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="固定" v-if="mode.selectMode == 2">
            <el-select v-model="settingForm.fixedDelivery" placeholder="哪根弦固定">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="每轮固定品格" v-if="mode.selectMode == 3">
            <el-switch v-model="mode.fixedRandom">
            </el-switch>
          </el-form-item>
          <el-form-item label="选择固定的品格" v-if="mode.fixedRandom">
            <el-select v-model="baseNumIndex" placeholder="选择固定的品格">
              <el-option v-for="item, key  in baseNums" :key="item" :label="item" :value="key">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-drawer>
    </div>
    <el-row class="config-zone">  
      <el-col :span="4" class="text-zone">
        <el-descriptions :column="1" :colon="false" size="small" border>
          <el-descriptions-item label="频率">{{ settingForm.interval }}s</el-descriptions-item>
          <el-descriptions-item label="模式">{{ showMode }}</el-descriptions-item>
          <el-descriptions-item label="弦"> <el-tag size="small">{{ showCurrentWire }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="品格">
            <el-tag size="small">{{ showNum }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="练习时间">
            <my-time :status="timeControl.status"></my-time>
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>
    <div class="ukulele-zone">
      <div class="ukulele">
        <div class="wire-1">
          <span class="show-wire-name">1弦</span>
          <div class="nums" v-if="showWire == 1">{{ showNum }}</div>
        </div>
        <div class="wire-2">
          <span class="show-wire-name">2弦</span>
          <div class="nums" v-if="showWire == 2">{{ showNum }}</div>
        </div>
        <div class="wire-3">
          <span class="show-wire-name">3弦</span>
          <div class="nums" v-if="showWire == 3">{{ showNum }}</div>
        </div>
        <div class="wire-4">
          <span class="show-wire-name">4弦</span>
          <div class="nums" v-if="showWire == 4">{{ showNum }}</div>
        </div>
      </div>
      <div class="control-zone">
        <el-button class="start-button" type="primary" ref="spaceBtn" @click="pressSpace">
          {{ spaceBtnText }}
        </el-button>
      </div>
    </div>
    <div class="bottom-zone">
      <div>
        <el-image :src="require('@/assets/gongan_logo.png')" class="gongan-logo"></el-image>
        <el-link :underline="false" href="https://beian.mps.gov.cn/#/query/webSearch?code=11010802043394"
          target="_blank" class="gongan-desc">京公网安备11010802043394</el-link>
        <el-link :underline="false" href="https://beian.miit.gov.cn/" target="_blank"
          class="icp-desc">京ICP备2023030962号</el-link>
      </div>
    </div>
  </div>
</template>

<script>
import MyTime from '../components/MyTime.vue'
import { steps } from "./homestep.js"
import Driver from 'driver.js';
import 'driver.js/dist/driver.min.css';

const MODE_RANDOM = 1
const MODE_FIXED = 2
const MODE_ORDER = 3

const MY_DRIVER = new Driver({
  doneBtnText: '完成',
  closeBtnText: '关闭',
  nextBtnText: '下一步',
  prevBtnText: '上一步',
})
export default {
  components: {
    MyTime
  },
  data() {
    return {
      timeControl: {
        status: false
      },
      mode: {
        selectMode: 2,
        options: [{ value: MODE_FIXED, label: "固定" }, { value: MODE_ORDER, label: "顺序" }, { value: MODE_RANDOM, label: "随机" }],
        fixedRandom: false,
      },
      baseNums: ['1234', '1243', '1324', '1342', '1423', '1432', '2134', '2143', '2314', '2341', '2413',
        '2431', '3124', '3142', '3214', '3241', '3412', '3421', '4123', '4132', '4213', '4312', '4321'],
      baseNumIndex: 0,
      options: [
        { value: 1, label: "1弦" },
        { value: 2, label: "2弦" },
        { value: 3, label: "3弦" },
        { value: 4, label: "4弦" }
      ],
      intervalItems: [
        { label: "800ms", value: 0.8 },
        { label: "1s", value: 1 },
        { label: "1.5s", value: 1.5 },
        { label: "2s", value: 2 },
        { label: "3s", value: 3 },
        { label: "4s", value: 4 },
        { label: "5s", value: 5 },
        { label: "6s", value: 6 },
        { label: "7s", value: 7 }
      ],
      settingForm: {
        fixedDelivery: 1,
        interval: 3,
      },
      direction: 'ttb',
      drawer: false,
      title: "尤克里里爬格子",
      showWire: 0,
      lastWire: 0,
      lastRandomIndex: 0,
      showNum: '1234',
      myInterval: {},
      isStart: false,
      spaceBtnText: "开始"
    }
  },
  computed: {
    showMode() {
      let mode = "";
      switch (this.mode.selectMode) {
        case MODE_RANDOM:
          mode = "随机";
          break;
        case MODE_FIXED:
          mode = "固定";
          break;
        case MODE_ORDER:
          mode = "顺序";
      }
      return mode;
    },
    showCurrentWire() {
      if (this.lastWire == 0) {
        return "空弦";
      } else {
        return this.lastWire + "弦";
      }
    }
  },
  created() {
    console.log("come on baby")
  },
  mounted() {
    window.addEventListener("keypress", this.pressSpace);
    MY_DRIVER.defineSteps(steps);
    if (localStorage.getItem("isGuide") != "true") {
      this.startGuide();
      localStorage.setItem("isGuide", "true");
    }
  }
  ,
  beforeDestroy() {
  },
  watch: {
    'settingForm.interval': {
      handler() {
        if (this.isStart) {
          clearInterval(this.myInterval)
          this.myInterval = setInterval(() => {
            this.changeWire(this.mode.selectMode)
          }, this.settingForm.interval * 1000);
        }
      },
    }
  },
  methods: {
    startGuide() {
      this.$nextTick(function () {
        MY_DRIVER.start();
      })

    },
    openDrawer(event) {
      console.log(event);
      if (!this.drawer) {
        this.drawer = true
      }
    },
    handleClose(done) { //回调的时候，传入了一个函数对象，需要我自己决定是否调用
      done();
    },
    pressSpace(event) {
      console.log(event);
      this.startLaunch();
      event.target.blur();
    },
    startLaunch() {
      if (!this.isStart) {
        console.log("启动")
        this.timeControl.status = true;
        this.myInterval = setInterval(() => {
          this.changeWire(this.mode.selectMode)
        }, this.settingForm.interval * 1000);
        this.isStart = true
        this.spaceBtnText = "停止"
      } else {
        console.log("停止")
        this.isStart = false
        this.timeControl.status = false;
        clearInterval(this.myInterval)
        this.spaceBtnText = "开始"
      }
    },
    changeWire(mode) {
      if (mode == MODE_RANDOM) {
        var randomWire = Math.ceil(Math.random() * 4)
        if (this.lastWire == randomWire) {
          randomWire += 1;
          if (randomWire > 4) {
            randomWire = 1;
          }
        }
      } else if (mode == MODE_FIXED) {
        randomWire = this.settingForm.fixedDelivery
      } else if (mode == MODE_ORDER) {
        randomWire = this.lastWire + 1 > 4 ? 1 : this.lastWire + 1;
      }
      this.showWire = randomWire;
      this.lastWire = randomWire;
      this.changeNumber(mode);
    },
    changeNumber(mode) {
      let randomIndex = 0;
      if (mode == MODE_ORDER) {
        if (this.mode.fixedRandom) {
          randomIndex = this.baseNumIndex;
        } else {
          if (this.lastRandomIndex == randomIndex) {
            randomIndex = randomIndex + 1;
          }
          if (randomIndex >= this.baseNums.length) {
            randomIndex = 0;
          }
        }
      } else {
        randomIndex = Math.floor(Math.random() * (this.baseNums.length - 1));
      }
      this.showNum = this.baseNums[randomIndex]
      this.lastRandomIndex = randomIndex;
    },
  }
}

</script>

<style scoped>
.ukulele-zone {
  width: 800px;
  height: 500px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}

.ukulele-zone .control-zone {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  text-align: center;
}

.ukulele-zone .control-zone .start-button {
  width: 500px;
}


.nav-zone {
  width: 50%;
  margin: auto;
  min-height: 60px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  background: var(--bg1_float);
  color: var(--text1);
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.ukulele-zone .ukulele {
  width: 500px;
  height: 300px;
  border-left: 2px solid black;
  border-right: 2px solid black;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.ukulele-zone .show-wire-name {
  position: absolute;
  width: 50px;
  height: 50px;
  text-align: center;
  color: red;
  top: -25px;
  font-size: 18px;
  left: -80px;
  line-height: 50px;
}




.ukulele-zone .ukulele .nums {
  position: absolute;
  width: 100%;
  text-align: center;
  top: -55px;
  left: 10px;
  letter-spacing: 35px;
  font-size: 80px;
}

.ukulele-zone .ukulele .wire-1 {
  border: 0.6px solid black;
  position: relative;
  height: 0.5px;
}

.ukulele-zone .ukulele .wire-2 {
  border: 0.3px solid black;
  margin-top: 100px;
  position: relative;
}


.ukulele-zone .ukulele .wire-3 {
  border: 0.3px solid black;
  margin-top: 100px;
  position: relative;
}

.ukulele-zone .ukulele .wire-4 {
  border: 0.6px solid black;
  margin-top: 94px;
  position: relative;
}

.nav-zone .right-zone .btn-help {}

.nav-zone .right-zone .btn-setting {}


.nav-zone .right-zone .btn-about-me {
  float: right;
  font-size: 12px;
}


.nav-zone .right-zone .btn-contact {}




.nav-zone .left-zone {
  margin-right: 50px;
}

.nav-zone .right-zone {
  margin-left: 50px;
  display: flex;
  width: 280px;
  justify-content: space-between;
  align-items: center;
}

.nav-zone .left-zone .logo {
  float: left;
  margin-top: 6px;
  margin-left: 6px;
}

.nav-zone .left-zone .title {
  float: left;
  font-size: 25px;
  margin: 5px 5px;
  font-weight: 400;
}


.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.control-zone .start-button {
  font-size: 25px;
}

.config-zone {
  margin-top: 30px;
  padding-left: 30px;
}

.config-zone .time-zone {
  float: right;
}

.bottom-zone {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 5px;
  font-size: 12px;
}

.bottom-zone .gongan-logo {
  width: 16px;
  float: left;
}

.bottom-zone .gongan-desc,
.bottom-zone .icp-desc {
  font-size: 12px;
  margin-bottom: 10px;
  margin-left: 5px;
}

@media screen and (max-width: 800px) {
  .nav-zone {
    width: 100%;
  }

  .nav-zone .left-zone {
    width: 100%;
    margin-right: 0;
  }

  .bottom-zone .gongan-desc {
    float: left;
    font-size: 12px;
    margin-bottom: 10px;
    margin-left: 5px;
  }

  .ukulele-zone {
    width: 66%;
    height: 62%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
  }

  .ukulele-zone .ukulele {
    width: 100%;
    height: 157px;
    border-left: 2px solid black;
    border-right: 2px solid black;
    position: absolute;
    left: 0;
    right: 0;
    top: 83px;
    bottom: 0;
  }

  .ukulele-zone .control-zone {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    text-align: center;
  }

  .ukulele-zone .control-zone .start-button {
    width: 80%;
  }

  .ukulele-zone .ukulele .nums {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -40px;
    left: 15px;
    letter-spacing: 26px;
    font-size: 60px;
  }

  .ukulele-zone .show-wire-name {
    position: absolute;
    width: 50px;
    height: 50px;
    text-align: center;
    color: red;
    font-size: 18px;
    top: -25px;
    left: -80px;
    line-height: 50px;
  }

  .ukulele-zone .ukulele .wire-2 {
    border: 0.3px solid black;
    margin-top: 50px;
    position: relative;
  }


  .ukulele-zone .ukulele .wire-3 {
    border: 0.3px solid black;
    margin-top: 50px;
    position: relative;
  }

  .ukulele-zone .ukulele .wire-4 {
    border: 0.6px solid black;
    margin-top: 52px;
    position: relative;
  }

}
</style>