<template>
    <div>
        <span class="text-zone">{{ showTime }}</span>
    </div>
</template>
<script>
export default {
    data() {
        return {
            currentTime: 0,
            intervalTimer: null,
        }
    },
    props: ["status"],
    computed: {
        showTime() {
            if (this.currentTime <= 60) {
                return this.currentTime + "秒";
            } else {
                let currentMinute = Math.floor(this.currentTime / 60);
                let currentSecond = this.currentTime % 60;
                return currentMinute + "分" + currentSecond + "秒";
            }
        }
    },
    mounted() {

    },
    watch: {
        status(newVal, oldVal) {
            console.log(oldVal);
            if (newVal) {
                this.intervalTimer = setInterval(() => {
                    this.currentTime += 1;
                }, 1000);
            } else {
                clearInterval(this.intervalTimer);
            }
        }
    }
}
</script>
<style scoped>
</style>