export const steps = [
    {
      element: '.config-zone',
      popover: {
        title: '当前信息',
        description: '包括频率、模式分为3种：随机、固定、顺序模式,以及目前选中的弦,还有练习时间',
        position: 'bottom'
      }
    },
    {
      element: '.nav-zone .btn-setting',
      popover: {
        title: '设置',
        description: '可以修改切换频率、爬格子的模式',
        position: 'left'
      },
    },
    {
      element: '.ukulele-zone',
      popover: {
        title: '爬格子区域',
        description: `左侧的1-4红色数字代表1弦-4弦，假设出现的是黑色的数字1234，代表1品2品3品4品`,
        position: 'bottom'
      }
    },
    {
      element: '.ukulele-zone .control-zone .start-button',
      popover: {
        title: '启动/停止按钮',
        description: `按下空格键启动/停止`,
        position: 'bottom'
      }
    },
  ]
  